import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
// import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function DefaultNavbarDropdown ({
  name,
  icon,
  children = false,
  collapseStatus = false,
  light = false,
  href = '',
  route = '',
  collapse,
  ...rest
}) {
  const linkComponent = {
    component: 'a',
    href,
    target: '_blank',
    rel: 'noreferrer'
  }

  const routeComponent = {
    component: Link,
    to: route
  }

  return (
    <>
      <Button
        variant='text'
        sx={{ mx: 1, p: 1, display: 'flex', borderRadius: 1, color: name === 'summer special' ? 'white !important' : 'default', background: name === 'summer special' ? '#e82730 !important' : 'default' }}
        {...rest}
        color='primary'
        // opacity={light ? 1 : 0.6}
        // sx={{ cursor: 'pointer', userSelect: 'none' }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        <Typography
          variant='button'
          textTransform='uppercase'
          color={light ? 'white' : 'dark'}
          sx={{ fontWeight: 'bold', ml: 1, mr: 0.25 }}
        >
          {name}
        </Typography>
        <Typography variant='body2' color={light ? 'white' : 'dark'} ml='auto'>
          {collapse && <KeyboardArrowDownIcon sx={{ fontWeight: 'bold', verticalAlign: 'middle' }} />}
        </Typography>
      </Button>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  )
}

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  // icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired
}

export default DefaultNavbarDropdown
