import TeamCard from './TeamCard'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { teams } from './teamData'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { IconButton } from '@mui/material'
import video from '../../assets/team.mp4'
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function Team () {
  const { batch, param } = useParams()
  const navigate = useNavigate()
  const [showTeam, setShowTeam] = useState(false)
  const [filter, setFilter] = useState('all')
  const [secondFilter, setSecondFilter] = useState('')
  const [play, setPlay] = useState(false)

  useEffect(() => {
    if (batch && ['all', 'dubai', 'abu-dhabi', 'management', 'ice-team'].includes(batch)) {
      if (param) {
        setFilter(batch)
        setSecondFilter(param)
      } else {
        setFilter(batch)
        setSecondFilter('')
      }
    } else {
      setFilter('all')
    }
  }, [batch, param])

  return (
    <Container sx={{ pt: 12 }} maxWidth='lg'>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Our Team'
        description='We are committed to guiding you to reach your goals, encourage your passion, pursue your dreams and achieve your maximum potential.'
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button variant='text' onClick={() => navigate('/team/all')} sx={{ color: filter === 'all' ? 'red' : 'default' }}>All</Button>
          <Button variant='text' onClick={() => navigate('/team/dubai')} sx={{ color: filter === 'dubai' ? 'red' : 'default' }}>Dubai</Button>
          <Button variant='text' onClick={() => navigate('/team/abu-dhabi')} sx={{ color: filter === 'abu-dhabi' ? 'red' : 'default' }}>Abu Dhabi</Button>
          <Button variant='text' onClick={() => navigate('/team/management')} sx={{ color: filter === 'management' ? 'red' : 'default' }}>Management</Button>
          <Button variant='text' onClick={() => navigate('/team/ice-team')} sx={{ color: filter === 'ice-team' ? 'red' : 'default' }}>Coaches</Button>
          <IconButton onClick={() => setPlay(true)}><PlayCircleFilledWhiteOutlinedIcon /></IconButton>
        </Grid>
        {teams.filter(t => t.set?.includes(filter))?.filter(t => t.set?.includes(secondFilter))?.map(team => <TeamCard key={team.image} {...{ team, setShowTeam }} />)}
      </Grid>
      <Dialog
        maxWidth='sm'
        open={!!showTeam}
        onClose={() => setShowTeam(false)}
      >
        <DialogContent sx={{ p: 0.4, height: '100%' }}>
          <IconButton sx={{ position: 'absolute', right: 18, top: 8, border: 'solid 1px white' }} onClick={() => setShowTeam(false)}><CloseOutlinedIcon sx={{ color: '#ffffff' }} /></IconButton>
          <Box component='img' src={showTeam} width='100%' sx={{ maxWidth: 360, borderRadius: 2 }} />
        </DialogContent>

      </Dialog>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            p: 0, m: 0, background: 'transparent'
          }
        }}
        open={play}
        onClose={() => setPlay(false)}
      >
        <DialogContent sx={{ p: 0 }}>
          <video width='360' controls autoPlay style={{ maxWidth: '94vw' }}>
            <source src={video} type='video/mp4' />
          </video>
        </DialogContent>
      </Dialog>
    </Container>
  )
}
