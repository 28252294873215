import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Link, useParams } from 'react-router-dom'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useEffect, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { ClassDetails } from './ClassDetails'
import ModalRegistration from '../../components/RegistrationForms/ModalRegistration'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function ClassesDubai () {
  const { article } = useParams()
  const readingRef = useRef(null)
  const [location, setLocation] = useState(null)
  // const w = window.innerWidth
  // const height = w < 576 ? '1692px' : w < 768 ? '970px' : '740px'
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (article) {
      setTimeout(() => readingRef.current.scrollIntoView(), 100)
    }
  }, [article])

  return (
    <Container>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Ice Skating Classes in Dubai'
        description='Whether you are looking to perfect your technique or simply enjoy a leisurely ice skate, join Emirates Skating Club classes in Dubai!'
      />
      <Box sx={{ pt: 14, pb: 2 }}>
        <Typography variant='h5' component='h1'>SCHEDULE OF CLASSES AT ESC DUBAI</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Box sx={{ height: 664, width: '100%', background: '#ddd' }}>
            {showSpinner &&
              <Box sx={{ width: '100%', pt: 12, textAlign: 'center' }}>
                <CircularProgress size={24} />
              </Box>}
            <iframe
              frameBorder='0'
              id='register'
              title='registration'
              style={{ width: '100%', height: '100%' }}
              onLoad={() => setShowSpinner(false)}
              src='https://forms.activitree.com/calendars/public/Gofhu6qSPsLjQ5SaC/week'
            />
          </Box>
          <Box ref={readingRef}>
            <Button variant='contained' sx={{ width: '100%', mt: 2 }} onClick={() => setLocation('dxb')}>Register</Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} sx={{ display: 'fled', flexDirection: 'column' }}>
          <Box sx={{ my: 'auto' }}>
            <Typography variant='subtitle2'>
              At the Emirates Skating Club in Dubai, ice-skating offers a unique blend of elegance and excitement amidst
              the desert heat. Nestled in the heart of the city, this state-of-the-art facility features a pristine ice
              rink where skaters of all levels can glide gracefully while enjoying the cool, refreshing atmosphere. With its focus on both recreational and competitive skating, the club provides an inviting environment for enthusiasts to hone their skills, take lessons from experienced coaches, or simply enjoy a fun day on the ice with family and friends.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button variant='contained' component={Link} to='/team/dubai/ice-team' target='_blank'>Coaches</Button>
              <Button variant='contained' sx={{ ml: 2 }} component={Link} to='/locations/dubai' target='_blank'>Location</Button>
            </Box>
          </Box>
        </Grid>
        <ClassDetails />
      </Grid>
      {!!location && <ModalRegistration {...{ location, setLocation }} />}
    </Container>
  )
}
