import InstaLogo from 'assets/insta.png'
import TikTokLogo from 'assets/tiktok.png'
import FB from 'assets/fb.png'
import YT from 'assets/yt.png'
import Typography from '@mui/material/Typography'
import logoCT from 'assets/esc-logo.png'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'

const date = new Date().getFullYear()

export default {
  brand: {
    name: 'Emirates Skating Club',
    image: logoCT,
    route: '/'
  },
  socials: [
    {
      icon: <Box component='img' src={InstaLogo} width='32px' />,
      link: 'https://www.instagram.com/emiratesskating'
    },
    {
      icon: <Box component='img' src={TikTokLogo} width='32px' />,
      link: 'https://www.tiktok.com/@emiratesskating/'
    },
    {
      icon: <Box component='img' src={FB} width='32px' />,
      link: 'https://www.facebook.com/emiratesskateclub'
    },
    {
      icon: <Box component='img' src={YT} width='32px' />,
      link: 'https://www.youtube.com/@Emiratesskating'
    }
  ],
  menus: [
    {
      name: 'company',
      items: [
        { name: 'about us', route: '/about' },
        { name: 'locations', route: '/locations' },
        { name: 'gallery', route: '/gallery' }
      ]
    },
    {
      name: 'classes',
      items: [
        { name: 'Abu Dhabi', route: '/classes/abu-dhabi' },
        { name: 'Dubai', route: '/classes/dubai' }
        // { name: 'schedule', route: '/classes' }
      ]
    },
    {
      name: 'help & support',
      items: [
        { name: 'contact', route: '/contact' },
        { name: 'knowledge center', route: '/knowledge' }
      ]
    },
    {
      name: 'legal',
      items: [
        { name: 'terms & conditions', route: '/terms' },
        { name: 'privacy policy', route: '/privacy' },
        { name: 'Codes of Conduct', route: '/codes-of-conduct' }
      ]
    }
  ],
  copyright: (
    <Typography variant='caption' fontWeight='regular'>
      All rights reserved. Copyright &copy; {date}&nbsp;
      <Link
        href='https://www.emiratesskating.com/'
        target='_blank'
        fontWeight='bold' rel='noreferrer'
      >
        Emirates Skating Club
      </Link>
      .
    </Typography>
  )
}
