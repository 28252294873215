import DubaiMap from '../../components/Maps/Dubai'
import AbuDhabiMap from '../../components/Maps/AbuDhabi'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Abh from 'assets/abh.jpg'
import Dxb from 'assets/dxb.jpg'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function Locations () {
  const { city } = useParams()
  const dubaiRef = useRef(null)

  useEffect(() => {
    if (city) {
      setTimeout(() => dubaiRef.current.scrollIntoView(), 100)
    }
  }, [city])

  return (
    <Container sx={{ pt: 12 }}>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Locations'
        description='Emirates Skating Club welcomes you at two esteemed ice rinks, conveniently located in Abu Dhabi and Dubai.'
      />
      <Card sx={{ p: 2, mb: 3 }}>
        <Typography component='h2' variant='h5' mb={2}>
          Abu Dhabi
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          Zayed Sports City Ice Rink
        </Typography>
        <Typography variant='body2' color='text' mb={2}>
          Olympic-sized rink for figure skating events, ice hockey matches, recreational skating & lessons.
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ height: { xs: 240, md: 240, lg: 380 }, backgroundImage: `url(${Abh})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
          <Grid item xs={12} md={6} sx={{ height: { xs: 320, md: 240, lg: 380 } }}>
            <AbuDhabiMap />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', my: 4 }}>
            <Button variant='contained' component={Link} to='/team/abu-dhabi/ice-team' target='_blank'>View Coaches</Button>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ p: 2, mb: 3 }}>
        <Typography component='h2' variant='h5' mb={2} ref={dubaiRef}>
          Dubai
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          Dubai Sport Society
        </Typography>
        <Typography variant='body2' color='text' mb={2}>
          Whether you’re training, watching the match, shopping or refuelling, Sport Society has a unique ambience. It’s full of energy. Bright, colourful and designed to inspire young and old alike to stay active and love sport.
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ height: { xs: 240, md: 240, lg: 380 }, backgroundImage: `url(${Dxb})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
          <Grid item xs={12} md={6} sx={{ xs: 320, md: 240, lg: 380 }}>
            <DubaiMap />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', my: 4 }}>
            <Button variant='contained' component={Link} to='/team/dubai/ice-team' target='_blank'>View Coaches</Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  )
}
