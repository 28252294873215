/* eslint-disable n/no-missing-require */
import PropTypes from 'prop-types'
import { Helmet } from 'localNpmModules/react-helmet-prepend/lib/Helmet'
import logoRound from 'assets/logo_180.png'

const hero = 'https://assets.emiratesskating.com/assets/emirates-skating-hero.jpg'

const HelmetMeta = ({ title, description }) => {
  const showDescription = description || 'Emirates Skating Club is the first officially registered figure skating club in the UAE, our aim is to promote the sport and to foster the personal growth and development of our members.'
  const defaultTitle = 'EMIRATES SKATING CLUB'
  const showTitle = title || defaultTitle
  const url = 'https://www.emiratesskating.com'

  return (
    <Helmet defaultTitle={showTitle}>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:url' content={url} />
      <meta name='twitter:title' content='EMIRATES SKATING CLUB' />
      <meta name='twitter:image:alt' content='Emirates Skating Club Hero Image' />
      <meta name='twitter:image' content={hero} />
      <meta property='og:locale' content='en_GB' />
      <meta name='og:logo' content={logoRound} />
      <meta property='og:site_name' content='EMIRATES SKATING CLUB' />
      <meta property='og:title' content='EMIRATES SKATING CLUB' />
      <meta property='og:description' content={showDescription?.substring(0, 175)} />
      <meta property='og:url' content={url} />
      <meta property='og:type' content='website' />
      <meta property='og:image' content={hero} />
      <meta property='og:image:alt' content='Emirates Skating Club Hero Image' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='760' />
      <meta name='description' content={showDescription} />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2048-2732.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2732-2048.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1668-2388.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2388-1668.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1536-2048.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2048-1536.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1488-2266.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2266-1488.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1640-2360.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2360-1640.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1668-2224.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2224-1668.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1620-2160.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2160-1620.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1290-2796.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2796-1290.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1179-2556.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2556-1179.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1284-2778.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2778-1284.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1170-2532.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2532-1170.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1125-2436.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2436-1125.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1242-2688.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2688-1242.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-828-1792.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1792-828.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1242-2208.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-2208-1242.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-750-1334.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1334-750.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-640-1136.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image' href={require('assets/pwa/apple-splash-1136-640.jpg')}
        media='(prefers-color-scheme: dark) and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link rel='apple-touch-icon' href={logoRound} />
    </Helmet>
  )
}

export default HelmetMeta

HelmetMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  twitter: PropTypes.string,
  size: PropTypes.object, // {width: ..., height: ...}
  type: PropTypes.string
}
