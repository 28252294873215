import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import createCache from '@emotion/cache'
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '../assets/theme'
import routes from '../routes'
import DefaultNavbar from './DefaultNavbar'
import Box from '@mui/material/Box'
import Footer from './Footer'
import ClassesDubai from '../pages/Classes/ClassesDubai'
import ClassesAbuDhabi from '../pages/Classes/ClassesAbuDhabi'
import HiddenRegisterDubai from '../pages/Registration/HiddenRegisterDubai'
import HiddenRegisterAbuDhabi from '../pages/Registration/HiddenRegisterAbuDhabi'
import HelmetMeta from './HelmetMeta'
import { analytics } from '../analytics'
import loadable from '@loadable/component'
import './App.css'
// import SeasonAbuDhabi from '../pages/SeasonOffer/SeasonAbuDhabi'
// import SeasonDubai from '../pages/SeasonOffer/SeasonDubai'

const NotFoundPage = loadable(() => import('../pages/Other/NotFoundPage'))
const CodesOfConduct = loadable(() => import('../pages/Other/CodesOfConduct'))
const Locations = loadable(() => import('../pages/Locations/Locations'))
const Knowledge = loadable(() => import('../pages/Other/Knowledge'))
const Terms = loadable(() => import('../pages/Other/Terms'))
const Privacy = loadable(() => import('../pages/Other/Privacy'))
const Contact = loadable(() => import('../pages/Contact/Contact'))
const Registration = loadable(() => import('../pages/Registration/Registration'))
const Home = loadable(() => import('../pages/Home/Home'))
const Team = loadable(() => import('../pages/Team/Team'))

export default function App () {
  const { pathname } = useLocation()

  useEffect(() => {
    navigator.serviceWorker && navigator.serviceWorker.register('sw.js').then(function (registration) { console.log('Excellent, registered with scope: ', registration.scope) })
  }, [])

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0

    analytics.page(window.location.pathname)
  }, [pathname])

  const getRoutes = (allRoutes) =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key || route.route} />
      }

      return null
    })

  const emotionCache = createCache({
    key: 'ecns', // emotion-cache-no-speedy
    speedy: false
  })

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={emotionCache}>
        <CssBaseline />
        <HelmetMeta />
        <DefaultNavbar
          routes={routes}
          action={{
            type: 'internal',
            route: '/register',
            label: 'Register',
            color: 'info'
          }}
          sticky
        />
        <Box sx={{ minHeight: '75vh' }}>
          <Routes>
            {getRoutes(routes)}
            <Route path='/' element={<Home />} />
            <Route path='/register' element={<Registration />} />
            <Route path='/locations/:city' element={<Locations />} />
            <Route path='/classes/dubai/:article' element={<ClassesDubai />} />
            <Route path='/classes/abu-dhabi/:article' element={<ClassesAbuDhabi />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/knowledge' element={<Knowledge />} />
            <Route path='/codes-of-conduct' element={<CodesOfConduct />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/team' element={<Team />} />
            <Route path='/team/:batch' element={<Team />} />
            <Route path='/team/:batch/:param' element={<Team />} />
            <Route path='/register/dubai' element={<HiddenRegisterDubai />} />
            <Route path='/register/abu-dhabi' element={<HiddenRegisterAbuDhabi />} />
            <Route element={<NotFoundPage />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </Box>

        <Box pt={6} px={1} mt={6}>
          <Footer />
        </Box>
      </CacheProvider>
    </ThemeProvider>
  )
}
