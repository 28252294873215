import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export default function ModalRegistration ({ location, setLocation }) {
  const [showSpinner, setShowSpinner] = useState(true)
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  const link = location === 'dxb' ? 'https://forms.activitree.com/reg/orgs/Gofhu6qSPsLjQ5SaC/form/fBx3es2AReTwPnevm/web' : 'https://forms.activitree.com/reg/orgs/WSQ73kswGwPiEdXwW/form/jHh5J4rzZSqieANRv/web'
  // const link = location === 'dxb' ? 'http://192.168.1.72:3900/reg/orgs/JZ3RBGse4aihFpeMC/form/123/web' : 'http://192.168.1.72:3900/reg/orgs/JZ3RBGse4aihFpeMC/form/123/web'

  return (
    <Dialog open={!!location} maxWidth={false} fullScreen={fullScreen}>
      <DialogContent sx={{ width: fullScreen ? '100%' : 706, height: fullScreen ? '100%' : '94vh', p: 0 }}>
        <IconButton onClick={() => setLocation(null)} sx={{ position: 'absolute', right: 0, top: 0, mr: 3, mt: 2.5 }} size='large'>
          <CloseOutlinedIcon />
        </IconButton>
        {showSpinner &&
          <Box sx={{ position: 'absolute', width: '100%', mt: '30%', textAlign: 'center', height: '92vh' }}>
            <CircularProgress size={24} />
          </Box>}
        <iframe
          frameBorder='0'
          id='register'
          title='registration'
          onLoad={() => setShowSpinner(false)}
          width='100%'
          height='98%'
          referrerPolicy='no-referrer-when-downgrade'
          loading='lazy'
          allowFullScreen
          aria-hidden='false'
          tabIndex='0'
          src={link}
        />
      </DialogContent>
    </Dialog>
  )
}
