import typography from 'assets/theme/base/typography'

import pxToRem from 'assets/theme/functions/pxToRem'

const { size } = typography

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl
    }
  }
}
