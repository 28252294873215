export default function AbuDhabiMap () {
  const zoom = 15
  const mapType = 'roadmap'
  const lat = '24.417129273626955'
  const lng = '54.45844219896332'
  const width = '100%'
  const height = '100%'
  const style = {}

  return (
    <iframe
      width={width}
      height={height}
      style={{ border: 0, filter: 'grayscale(30%)', ...style }}
      frameBorder='0'
      referrerPolicy='no-referrer-when-downgrade'
      loading='lazy'
      allowFullScreen
      aria-hidden='false'
      tabIndex='0'
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA5f6xxPvHUlkCFxkAct5wbG1ylJmSqGRU&zoom=${zoom}&maptype=${mapType}&q=${lat},${lng}`}
    />
  )
}
