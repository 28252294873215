import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Logo from 'assets/emirates-sc.png'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function About () {
  return (
    <Container>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | About The Club'
        description='Emirates Skating Club promotes the sport of figure skating at the local, national, and international levels in a safe and healthy environment.'
      />
      <Box sx={{ width: '100%', pt: 14, textAlign: 'center' }}>
        <Box component='img' width='160px' height='160px' src={Logo} />
        <Typography>
          Building a skating club for youth
        </Typography>

      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant='h5' component='h1'>
          PRESIDENT&apos;S MESSAGE
        </Typography>
        <Typography variant='body2' component='h2'>
          On behalf of the Board members and Club management, we are delighted to have you as a member of the Emirates Skating Club. As the first officially registered figure skating club in the UAE, our aim is to promote the sport and to foster the personal growth and development of our members. The Emirates Skating Club operates on the principle that skaters of all levels and ages have the opportunity for success and growth in a fun, safe and committed environment. If you are a recreational skater or an Olympic hopeful, a beginner or advanced, our goal is to offer you the highest of standards with five experienced and qualified international coaches. Our staff are committed to guiding you to reach your goals, encourage your passion, pursue your dreams and achieve your maximum potential. Our mission statement is to, “Encourage the sport of figure skating in UAE. It is to develop mutual respect, fellowship, self-discipline, fairness, team spirit, self-reliance, poise and self-confidence within an environment of the highest standards of leadership, professionalism and good sportsmanship&quot;. Our vision is to, &quot;Develop the sport of figure skating in the UAE to reach and surpass international standards&quot;. We wish you all much success in this skating season and look forward to seeing you on the ice.
        </Typography>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography variant='h5' component='h1'>
          ABOUT THE CLUB
        </Typography>
        <Typography variant='body2' component='h2'>
          Emirates Skating Club is the first registered and licensed figure skating club in the United Arab Emirates. The club was established to promote and develop the sport of figure skating. We wish to provide an atmosphere of mutual respect and social interaction, while promoting positive role models, good sportsmanship and lasting friendships. We hope to inspire strong qualities of discipline, poise, and self confidence that will continue throughout the members life. By maintaining excellence in our training, organization and programs, we foster an environment supportive to social, recreational and competitive ice skating. Through Emirates Skating Club&apos;s opportunities and guidance, new skaters begin confident skaters, social and recreational skaters achieve their personal goals and competitive skaters realize their dreams of becoming champions. Emirates Skating Club follows and implements all standards, rules and regulations required by the United Arab Emirates Ice Sport Federation, UAE sport authorities, International Skating Union and International Skating Institute of Asia.
        </Typography>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant='h5' component='h1'>
          OUR MISSION
        </Typography>
        <Typography variant='body2' component='h2'>
          Promote the sport of figure skating at the local, national, and international levels in a safe and healthy environment.
        </Typography>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant='h5' component='h1'>
          OUR VISION
        </Typography>
        <Typography variant='body2' component='h2'>
          Develop the sport of figure skating in the United Arab Emirates to reach and surpass international standards
        </Typography>
      </Box>
    </Container>
  )
}
