import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

const HiddenRegisterAbuDhabi = () => {
  const [showSpinner, setShowSpinner] = useState(true)
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <Box sx={{ width: fullScreen ? '100%' : 706, height: '94vh', p: 0, mx: 'auto', pt: 10 }}>
      {showSpinner &&
        <Box sx={{ position: 'absolute', width: '100%', mt: '30%', textAlign: 'center', height: '92vh' }}>
          <CircularProgress size={24} />
        </Box>}
      <iframe
        frameBorder='0'
        id='register'
        title='registration'
        onLoad={() => setShowSpinner(false)}
        width='100%'
        height='98%'
        referrerPolicy='no-referrer-when-downgrade'
        loading='lazy'
        allowFullScreen
        aria-hidden='false'
        tabIndex='0'
        src='https://forms.activitree.com/reg/orgs/WSQ73kswGwPiEdXwW/form/jHh5J4rzZSqieANRv/qrdesk1'
      />
    </Box>
  )
}

export default HiddenRegisterAbuDhabi
