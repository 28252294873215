import colors from 'assets/theme/base/colors'

const { transparent } = colors

export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main
    }
  }
}
