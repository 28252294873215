import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import bgImage from 'assets/skate-art.jpg'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import { Link as RouterLink } from 'react-router-dom'
import HelmetMeta from '../../layouts/HelmetMeta'

function Contact () {
  return (
    <Container>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Contact Us'
        description='Emirates Skating Club would love to hear from you, in person, on social, a call or email.'
      />
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={12} lg={6}>
          <Box
            display={{ xs: 'none', lg: 'flex' }}
            sx={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ml: 2, mt: 2, width: 'calc(100% - 2rem)', height: 'calc(100vh - 22rem)', borderRadius: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={6} xl={4} sx={{ ml: { xs: 'auto', lg: 0 }, mr: { xs: 'auto', lg: 0 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: { xs: 20, sm: 18, md: 20 }, mb: { xs: 20, sm: 18, md: 20 }, mx: 3 }}>
            <Box sx={{ p: 2, mx: 2, mt: -3 }}>
              <Typography variant='h3'>
                Contact
              </Typography>
            </Box>
            <Box p={3}>
              <Typography variant='body2'>
                Always at your service by phone, email or at our locations during schedule.
              </Typography>
              <Divider />
              <Typography variant='body2' mb={3}>
                <b>Email: </b> <Link href='mailto: info@emiratesskating.com' target='_blank' rel='noreferrer'> info@emiratesskating.com </Link>
              </Typography>
              <Typography variant='body2' mb={3}>
                <b>Abu Dhabi</b><br />
                Zayed Sports City, Abu Dhabi Ice Rink, UAE<br />
                <Link href='tel:+97126737371' target='_blank' rel='noreferrer'>  + 971 2673 7371 </Link><br />
                <Link href='tel:+971586333908' target='_blank' rel='noreferrer'> + 971 58 633 3908 </Link><br />
              </Typography>
              <Button variant='outlined' size='small' sx={{ borderColor: 'black !important', color: 'black !important' }} component={RouterLink} to='/locations'>View Location</Button>
              <Divider />
              <Typography variant='body2' mb={3}>
                <b>Dubai</b><br />
                Sport Society Ice Rink, Mirdif, Dubai, UAE<br />
                <Link href='tel:+97126737371' target='_blank' rel='noreferrer'>  + 971 55 421 2038</Link><br />
                <Link href='tel:+971586333908' target='_blank' rel='noreferrer'> + 971 55 751 5073 </Link><br />
              </Typography>
              <Button variant='outlined' size='small' sx={{ borderColor: 'black !important', color: 'black !important' }} component={RouterLink} to='/locations/dubai'>View Location</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Contact
