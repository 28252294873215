import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'EmiratesSkating',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-N3GMML6BVL']
    })
  ]
})

export { analytics }
