import Typography from '@mui/material/Typography'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'

const zahra = (
  <Typography variant='subtitle2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 5x UAE National Champion&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> International Competitor&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Olympic Qualifications candidate&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Emirates Skating Club Mentor / Instructor&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Co-founder Emirates Skating Club&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Nike Ambassador&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Etihad Airlines official training partner&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 2019 Special Olympics Athlete Engagement Committee member&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 2019 Torch bearer Special Olympics MENA Games
  </Typography>
)

const attila = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> ISU Judge / Singles, Pairs & Ice Dance&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> ISU Technical Controller / Singles&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> ISU Seminar Host&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> UAEWSF Coach Examiner
  </Typography>
)

const bohdan = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 32 years experience on  ice-team&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Single and Pairs Skating&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Principal soloist of Imperial  ice-team Stars, Theatre on  ice-team for the last 10 years&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Rehearsal director and artist Moscow Circus On  ice-team&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Conducted master classes around the world&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Coaching for any age and any sports preparation.
  </Typography>
)

const alina = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure skater with 28 years experience on  ice-team&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Awarded Master of Sports in Ukraine (Ice Dance)&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> National team member for Juniors and Seniors&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} />  ice-team dancer, single and pair professional  ice-team performer&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 2x Ukrainian Junior National Championships&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Competed in Junior Grand Prix from 2004 to 2008&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Junior World Championships 2005 to 2006 and the Universiade 2007 &nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Principal character roles in The Imperial  ice-team Stars productions of Sleeping Beauty, Nutcracker and Cinderella&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 8 years experienced in the show &nbsp;&nbsp;&nbsp;
  </Typography>
)

const mark = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Collaborates with the Russian Olympic committee, Skating Federation and Alexei Mishin&apos;s Team.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 19 years experience on  ice-team&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 13 years coaching experience&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure skater between 2003 and 2012&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure skating judge from 2012 to 2019&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure skating coach from 2014 to today&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Assistant of Senior Coach of Nina Mozer&apos;s figure skating club and Marjanski Team club &nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Off-ice and on-ice coach at Global Online Training Platform for Figure Skaters &quot;Victory Skating Academy&quot;&nbsp;&nbsp;&nbsp;
  </Typography>
)

const roquiya = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <b>WELCOME TO EMIRATES SKATING CLUB!</b><br />
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Our staff are committed to guiding you to reach your goals, encourage your passion, pursue your dreams and achieve your maximum potential. Our mission statement is to, &quot;Encourage the sport of figure skating in UAE. It is to develop mutual respect, fellowship, self-discipline, fairness, team spirit, self-reliance, poise and self-confidence within an environment of the highest standards of leadership, professionalism and good sportsmanship&quot;.
  </Typography>
)

const lilibeth = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>

  </Typography>
)

/*
const dmitrii = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 15 years coaching experience.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Coach-Choreographer.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Soloist of world  ice-team shows.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Organizer of sports camps and figure skating master classes in many cities of Russia, Europe, as well as in the USA and the UAE.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Participant of dance concerts of Alla Dukhova&apos;s show ballet &quot;Todes&quot; and  ice-team shows.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> T.Tarasova, I.Bobrina, I.Averbukh, E.Berezhnaya, &quot;Russian  ice-team Stars&quot; (England).
  </Typography>
)
 */

const elen = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Speciality: Jumps, Technique, Spins, Off-ice conditioning, Creating program choreography.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 24 years of experience.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure skating career: 1983 – 1997.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Multiple Champion of Armenia.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Member of the Armenian National Team: 1986 – 1977.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Participant of International Competitions, Europe Championship.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Ballet on  ice-team Skater: 1990-1991.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure Skating Coach: 2013 – Present time.
  </Typography>
)

const gabija = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Coaches skaters of all ages and levels.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 21 years skating experience and 10 years of professional international coaching.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure Skating Coach since 2013.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Sports Therapist.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Physio Therapist.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Sports massage.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> International competitor representing Lithuania.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Coaches beginner to senior, amateur to professional.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Participated in National championships and International competitions.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Figure Skating Camps with Irina Lobacheva (2002 Olympic Silver Medalist, 2002 World Champion, 2003 European Champion).
  </Typography>
)

const jhovet = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>

  </Typography>
)

const julia = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>

  </Typography>
)

const veronika = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 3x World Champion.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> 6x National Champion.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Member of the Russian National Team.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Head Coach- Director | Online Figure Skating.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Works with Athletes from beginner level to National team members.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> On-Ice, Off-Ice, Flexibility and Stretching, Ballet and Choreography.&nbsp;&nbsp;&nbsp;
    <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Synchronized Skating Team “Paradise”.
  </Typography>
)

const king = (
  <Typography variant='body2' color='textSecondary' sx={{ pl: 1 }}>

  </Typography>
)

export const teams = [
  {
    name: 'ROQUIYA COCHRAN',
    title: 'President, UAE',
    flag: '🇦🇪',
    nation: 'AE',
    description: roquiya,
    citation: '',
    image: 'roquiya',
    set: 'all abu-dhabi management dubai'
  },
  {
    name: 'ZAHRA LARI',
    title: 'CEO, UAE',
    flag: '🇦🇪',
    nation: 'AE',
    description: zahra,
    citation: 'There will always be people against you, people doubting you and people not believing in you. Just trust yourself and prove them wrong.',
    image: 'zahra',
    education: 'BS of Science in Environment Health & Safety',
    set: 'all abu-dhabi management dubai'
  },
  {
    name: 'ATTILA BALAZS SOOS',
    title: 'ESC Advisor',
    flag: '🇭🇺',
    nation: 'HU',
    description: attila,
    citation: 'It always seems impossible until it\'s done',
    image: 'attila',
    side: 'left',
    education: 'PE & Sports Science Diploma',
    set: 'all abu-dhabi  ice-team',
    city: 'abh'
  },
  {
    name: 'BOHDAN BEREZENKO',
    title: 'Head Coach/Jump Specialist/Choreographer',
    flag: '🇺🇦',
    nation: 'UA',
    description: bohdan,
    citation: 'All victories begin with a victory over themselves.',
    image: 'bohdan',
    education: 'Pedagogical University and Financial Management',
    set: 'all abu-dhabi  ice-team',
    city: 'abh'
  },
  {
    name: 'ALINA SAPRYKINA',
    title: 'Figure Skating Coach',
    flag: '🇺🇦',
    nation: 'UA',
    description: alina,
    citation: 'Success doesn\'t come to you. You go to it',
    image: 'alina',
    side: 'left',
    education: 'Academy of Physical Culture and Sports',
    set: 'all abu-dhabi  ice-team',
    city: 'abh'
  },
  {
    name: 'ELEN HAMBARDZUMYAN',
    title: 'Coach / Spin Technical Analyst',
    flag: '🇦🇲',
    nation: 'AM',
    description: elen, // jsx component
    education: 'Candidate of Master of Sport Degree - Figure Skating',
    citation: 'You never fail until you stop trying.',
    image: 'elen',
    set: 'all abu-dhabi ice-team',
    city: 'abh'
  },
  {
    name: 'MARK BELKIN',
    title: 'Club Director/Figure Skating Coach',
    flag: '🇷🇺',
    nation: 'RU',
    description: mark,
    citation: 'Limitations exist only in your mind.',
    image: 'mark',
    education: 'Master Degree Figure skating',
    set: 'all dubai ice-team',
    city: 'dxb'
  },
  /*
  {
    name: 'DMITRII LAPSIHN',
    title: 'Coach/Choreographer/Skating Skills Specialist',
    flag: '🇷🇺',
    nation: 'RU',
    description: dmitrii, // jsx component
    education: 'Master of Sports in mens single skating',
    citation: 'Movement is life.',
    image: 'dmitrii',
    set: 'all dubai ice-team',
    city: 'dxb'
  },
   */
  {
    name: 'GABIJA MEILUNAITE',
    title: 'Coach/Sports Therapist/Rehabilitation Therapist',
    flag: '🇱🇹',
    nation: 'LT',
    description: gabija, // jsx component
    education: 'Bachelor of Science (HONS) in Sports Therapy and Rehabilitation',
    citation: 'We fall, we learn, we rise.',
    image: 'gabija',
    set: 'all dubai ice-team',
    city: 'dxb'
  },
  {
    name: 'VERONIKA GOROKHOVA',
    title: 'Coach/Choreographer',
    flag: '🇷🇺',
    nation: 'RU',
    description: veronika, // jsx component
    education: 'Bachelor of Education, Master of Sport',
    citation: 'If you can dream it, then you can achieve it.',
    image: 'veronika',
    set: 'all dubai ice-team',
    city: 'dxb'
  },
  {
    name: 'LILIBETH BORJA VICENCIO',
    title: 'Office Manager',
    flag: '🇵🇭',
    nation: 'PH',
    description: lilibeth, // jsx component
    citation: 'Hard work has no alternative',
    image: 'lilibeth',
    side: 'left',
    set: 'all abu-dhabi management',
    city: 'abh'
  },
  {
    name: 'Julia Marjorie Vicencio',
    title: 'Office Administrator',
    flag: '🇵🇭',
    nation: 'PH',
    description: julia, // jsx component
    education: 'Computer Programming and Animation',
    citation: 'Love your family, work super hard, live your passion.',
    image: 'julia',
    set: 'all dubai management',
    city: 'dxb'
  },
  {
    name: 'Jhovet Peralta',
    title: 'Office Assistant',
    flag: '🇵🇭',
    nation: 'PH',
    description: jhovet, // jsx component
    education: 'BSBA Management',
    citation: 'Optimism is a Happiness magnet. If you stay positive, good things and good people will be drawn to you.',
    image: 'jhovet',
    set: 'all dubai management',
    city: 'dxb'
  },
  {
    name: 'King Jim Castro',
    title: 'Sales Associate',
    flag: '🇵🇭',
    nation: 'PH',
    description: king, // jsx component
    education: 'Hospitality Services at “Pamantasan ng Marikina College”.',
    citation: 'Every perfect life is a parable invented by God',
    image: 'king',
    set: 'all dubai management',
    city: 'dxb'
  }
]
