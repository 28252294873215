import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Comps from 'assets/comps.jpg'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function Competitions () {
  const [showResults, setShowResults] = useState(false)
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <Container sx={{ pt: 12 }}>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Skate Emirates'
        description='We host anually the largest ice skating competion in the Middle East. We hope to see you in our next ice skating competion.'
      />
      <Box sx={{ width: '100%', height: 420, backgroundImage: `url(${Comps})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '0 50%' }}>
        <Typography variant='h1' sx={{ p: 4, color: '#ffffff', textShadow: '0 2px 2px #000' }}>Skate Emirates Competition</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Paper sx={{ p: 4 }} elevation={0}>
          View past competitions results<br />
          <Button vartiant='contained' onClick={() => setShowResults('https://skate-emirates.s3.eu-central-1.amazonaws.com/2022/index.htm')}>2022</Button>
          <Button vartiant='contained' onClick={() => setShowResults('https://skate-emirates.s3.eu-central-1.amazonaws.com/2023/index.htm')}>2023</Button>
          <Button vartiant='contained' onClick={() => setShowResults('https://hunskate.hu/external/mukorcsolya/2023_2024/SKE2024/index.htm')}>2024</Button>
        </Paper>
      </Box><br />

      {!!showResults &&
        <Dialog
          aria-labelledby='results'
          aria-describedby='competition-results'
          fullScreen={fullScreen}
          maxWidth='xxl'
          open={!!showResults}
          onClose={() => setShowResults(false)}
        >
          <DialogActions><Button onClick={() => setShowResults(false)}>Close</Button></DialogActions>
          <DialogContent sx={{ minWidth: '98vw', height: '90vh' }}>
            <iframe
              width='100%'
              height='100%'
              style={{ border: 0 }}
              frameBorder='0'
              referrerPolicy='no-referrer-when-downgrade'
              loading='lazy'
              allowFullScreen
              aria-hidden='false'
              tabIndex='0'
              src={showResults}
            />
          </DialogContent>
        </Dialog>}

    </Container>
  )
}
