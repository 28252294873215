/* eslint-disable */
'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const React = require('react')
const PropTypes = require('prop-types')
const withSideEffect = require('react-side-effect')
const isEqual = require('react-fast-compare')
const objectAssign = require('object-assign')

function _objectWithoutProperties (e, t) {
  if (e == null) return {}
  let o
  let r
  const i = _objectWithoutPropertiesLoose(e, t)
  if (Object.getOwnPropertySymbols) {
    const s = Object.getOwnPropertySymbols(e)
    for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o])
  }
  return i
}
function _objectWithoutPropertiesLoose (r, e) {
  if (r == null) return {}
  const t = {}
  for (const n in r) {
    if ({}.hasOwnProperty.call(r, n)) {
      if (e.includes(n)) continue
      t[n] = r[n]
    }
  }
  return t
}

const ATTRIBUTE_NAMES = {
  BODY: 'bodyAttributes',
  HTML: 'htmlAttributes',
  TITLE: 'titleAttributes'
}
const TAG_NAMES = {
  BASE: 'base',
  BODY: 'body',
  HEAD: 'head',
  HTML: 'html',
  LINK: 'link',
  META: 'meta',
  NOSCRIPT: 'noscript',
  SCRIPT: 'script',
  STYLE: 'style',
  TITLE: 'title'
}
const VALID_TAG_NAMES = Object.values(TAG_NAMES)
const TAG_PROPERTIES = {
  CHARSET: 'charset',
  CSS_TEXT: 'cssText',
  HREF: 'href',
  HTTPEQUIV: 'http-equiv',
  INNER_HTML: 'innerHTML',
  ITEM_PROP: 'itemprop',
  NAME: 'name',
  PROPERTY: 'property',
  REL: 'rel',
  SRC: 'src',
  TARGET: 'target'
}
const REACT_TAG_MAP = {
  accesskey: 'accessKey',
  charset: 'charSet',
  class: 'className',
  contenteditable: 'contentEditable',
  contextmenu: 'contextMenu',
  'http-equiv': 'httpEquiv',
  itemprop: 'itemProp',
  tabindex: 'tabIndex'
}
const HELMET_PROPS = {
  DEFAULT_TITLE: 'defaultTitle',
  DEFER: 'defer',
  ENCODE_SPECIAL_CHARACTERS: 'encodeSpecialCharacters',
  ON_CHANGE_CLIENT_STATE: 'onChangeClientState',
  TITLE_TEMPLATE: 'titleTemplate'
}
const HTML_TAG_MAP = Object.keys(REACT_TAG_MAP).reduce((obj, key) => {
  obj[REACT_TAG_MAP[key]] = key
  return obj
}, {})
const HELMET_ATTRIBUTE = 'data-react-helmet'

const getTitleFromPropsList = propsList => {
  const innermostTitle = getInnermostProperty(propsList, TAG_NAMES.TITLE)
  const innermostTemplate = getInnermostProperty(propsList, HELMET_PROPS.TITLE_TEMPLATE)
  if (innermostTemplate && innermostTitle) {
    // use function arg to avoid need to escape $ characters
    return innermostTemplate.replace(/%s/g, () => Array.isArray(innermostTitle) ? innermostTitle.join('') : innermostTitle)
  }
  const innermostDefaultTitle = getInnermostProperty(propsList, HELMET_PROPS.DEFAULT_TITLE)
  return innermostTitle || innermostDefaultTitle || undefined
}
const getOnChangeClientState = propsList => {
  return getInnermostProperty(propsList, HELMET_PROPS.ON_CHANGE_CLIENT_STATE) || (() => {})
}
const getAttributesFromPropsList = (tagType, propsList) => {
  return propsList.filter(props => typeof props[tagType] !== 'undefined').map(props => props[tagType]).reduce((tagAttrs, current) => {
    return {
      ...tagAttrs,
      ...current
    }
  }, {})
}
const getBaseTagFromPropsList = (primaryAttributes, propsList) => {
  return propsList.filter(props => typeof props[TAG_NAMES.BASE] !== 'undefined').map(props => props[TAG_NAMES.BASE]).reverse().reduce((innermostBaseTag, tag) => {
    if (!innermostBaseTag.length) {
      const keys = Object.keys(tag)
      for (let i = 0; i < keys.length; i++) {
        const attributeKey = keys[i]
        const lowerCaseAttributeKey = attributeKey.toLowerCase()
        if (primaryAttributes.indexOf(lowerCaseAttributeKey) !== -1 && tag[lowerCaseAttributeKey]) {
          return innermostBaseTag.concat(tag)
        }
      }
    }
    return innermostBaseTag
  }, [])
}
const getTagsFromPropsList = (tagName, primaryAttributes, propsList) => {
  // Calculate list of tags, giving priority innermost component (end of the propslist)
  const approvedSeenTags = {}
  return propsList.filter(props => {
    if (Array.isArray(props[tagName])) {
      return true
    }
    if (typeof props[tagName] !== 'undefined') {
      warn('Helmet: ' + tagName + ' should be of type "Array". Instead found type "' + typeof props[tagName] + '"')
    }
    return false
  }).map(props => props[tagName]).reverse().reduce((approvedTags, instanceTags) => {
    const instanceSeenTags = {}
    instanceTags.filter(tag => {
      let primaryAttributeKey
      const keys = Object.keys(tag)
      for (let i = 0; i < keys.length; i++) {
        const attributeKey = keys[i]
        const lowerCaseAttributeKey = attributeKey.toLowerCase()

        // Special rule with link tags, since rel and href are both primary tags, rel takes priority
        if (primaryAttributes.indexOf(lowerCaseAttributeKey) !== -1 && !(primaryAttributeKey === TAG_PROPERTIES.REL && tag[primaryAttributeKey].toLowerCase() === 'canonical') && !(lowerCaseAttributeKey === TAG_PROPERTIES.REL && tag[lowerCaseAttributeKey].toLowerCase() === 'stylesheet')) {
          primaryAttributeKey = lowerCaseAttributeKey
        }
        // Special case for innerHTML which doesn't work lowercased
        if (primaryAttributes.indexOf(attributeKey) !== -1 && (attributeKey === TAG_PROPERTIES.INNER_HTML || attributeKey === TAG_PROPERTIES.CSS_TEXT || attributeKey === TAG_PROPERTIES.ITEM_PROP)) {
          primaryAttributeKey = attributeKey
        }
      }
      if (!primaryAttributeKey || !tag[primaryAttributeKey]) {
        return false
      }
      const value = tag[primaryAttributeKey].toLowerCase()
      if (!approvedSeenTags[primaryAttributeKey]) {
        approvedSeenTags[primaryAttributeKey] = {}
      }
      if (!instanceSeenTags[primaryAttributeKey]) {
        instanceSeenTags[primaryAttributeKey] = {}
      }
      if (!approvedSeenTags[primaryAttributeKey][value]) {
        instanceSeenTags[primaryAttributeKey][value] = true
        return true
      }
      return false
    }).reverse().forEach(tag => approvedTags.push(tag))

    // Update seen tags with tags from this instance
    const keys = Object.keys(instanceSeenTags)
    for (let i = 0; i < keys.length; i++) {
      const attributeKey = keys[i]
      const tagUnion = objectAssign({}, approvedSeenTags[attributeKey], instanceSeenTags[attributeKey])
      approvedSeenTags[attributeKey] = tagUnion
    }
    return approvedTags
  }, []).reverse()
}
const getInnermostProperty = (propsList, property) => {
  for (let i = propsList.length - 1; i >= 0; i--) {
    const props = propsList[i]
    if (props.hasOwnProperty(property)) {
      return props[property]
    }
  }
  return null
}
const reducePropsToState = propsList => ({
  baseTag: getBaseTagFromPropsList([TAG_PROPERTIES.HREF, TAG_PROPERTIES.TARGET], propsList),
  bodyAttributes: getAttributesFromPropsList(ATTRIBUTE_NAMES.BODY, propsList),
  defer: getInnermostProperty(propsList, HELMET_PROPS.DEFER),
  encode: getInnermostProperty(propsList, HELMET_PROPS.ENCODE_SPECIAL_CHARACTERS),
  htmlAttributes: getAttributesFromPropsList(ATTRIBUTE_NAMES.HTML, propsList),
  title: getTitleFromPropsList(propsList),
  titleAttributes: getAttributesFromPropsList(ATTRIBUTE_NAMES.TITLE, propsList),
  linkTags: getTagsFromPropsList(TAG_NAMES.LINK, [TAG_PROPERTIES.REL, TAG_PROPERTIES.HREF], propsList),
  metaTags: getTagsFromPropsList(TAG_NAMES.META, [TAG_PROPERTIES.NAME, TAG_PROPERTIES.CHARSET, TAG_PROPERTIES.HTTPEQUIV, TAG_PROPERTIES.PROPERTY, TAG_PROPERTIES.ITEM_PROP], propsList),
  noscriptTags: getTagsFromPropsList(TAG_NAMES.NOSCRIPT, [TAG_PROPERTIES.INNER_HTML], propsList),
  onChangeClientState: getOnChangeClientState(propsList),
  scriptTags: getTagsFromPropsList(TAG_NAMES.SCRIPT, [TAG_PROPERTIES.SRC, TAG_PROPERTIES.INNER_HTML], propsList),
  styleTags: getTagsFromPropsList(TAG_NAMES.STYLE, [TAG_PROPERTIES.CSS_TEXT], propsList)
})
const rafPolyfill = (() => {
  let clock = Date.now()
  return callback => {
    const currentTime = Date.now()
    if (currentTime - clock > 16) {
      clock = currentTime
      callback(currentTime)
    } else {
      setTimeout(() => {
        rafPolyfill(callback)
      }, 0)
    }
  }
})()
const cafPolyfill = id => clearTimeout(id)
const requestAnimationFrame = typeof window !== 'undefined' ? window.requestAnimationFrame && window.requestAnimationFrame.bind(window) || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || rafPolyfill : global.requestAnimationFrame || rafPolyfill
const cancelAnimationFrame = typeof window !== 'undefined' ? window.cancelAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame || cafPolyfill : global.cancelAnimationFrame || cafPolyfill
const warn = msg => {
  return console && typeof console.warn === 'function' && console.warn(msg)
}
let _helmetCallback = null
const handleClientStateChange = newState => {
  if (_helmetCallback) {
    cancelAnimationFrame(_helmetCallback)
  }
  if (newState.defer) {
    _helmetCallback = requestAnimationFrame(() => {
      commitTagChanges(newState, () => {
        _helmetCallback = null
      })
    })
  } else {
    commitTagChanges(newState)
    _helmetCallback = null
  }
}
const commitTagChanges = (newState, cb) => {
  const {
    title,
    titleAttributes,
    baseTag,
    bodyAttributes,
    htmlAttributes,
    linkTags,
    metaTags,
    noscriptTags,
    onChangeClientState,
    scriptTags,
    styleTags
  } = newState
  updateAttributes(TAG_NAMES.BODY, bodyAttributes)
  updateAttributes(TAG_NAMES.HTML, htmlAttributes)
  updateTitle(title, titleAttributes)
  const tagUpdates = {
    baseTag: updateTags(TAG_NAMES.BASE, baseTag),
    linkTags: updateTags(TAG_NAMES.LINK, linkTags),
    metaTags: updateTags(TAG_NAMES.META, metaTags),
    noscriptTags: updateTags(TAG_NAMES.NOSCRIPT, noscriptTags),
    scriptTags: updateTags(TAG_NAMES.SCRIPT, scriptTags),
    styleTags: updateTags(TAG_NAMES.STYLE, styleTags)
  }
  const addedTags = {}
  const removedTags = {}
  Object.keys(tagUpdates).forEach(tagType => {
    const {
      newTags,
      oldTags
    } = tagUpdates[tagType]
    if (newTags.length) {
      addedTags[tagType] = newTags
    }
    if (oldTags.length) {
      removedTags[tagType] = tagUpdates[tagType].oldTags
    }
  })
  cb && cb()
  onChangeClientState(newState, addedTags, removedTags)
}
const flattenArray = possibleArray => {
  return Array.isArray(possibleArray) ? possibleArray.join('') : possibleArray
}
const updateTitle = (title, attributes) => {
  if (typeof title !== 'undefined' && document.title !== title) {
    document.title = flattenArray(title)
  }
  updateAttributes(TAG_NAMES.TITLE, attributes)
}
const updateAttributes = (tagName, attributes) => {
  const elementTag = document.getElementsByTagName(tagName)[0]
  if (!elementTag) {
    return
  }
  const helmetAttributeString = elementTag.getAttribute(HELMET_ATTRIBUTE)
  const helmetAttributes = helmetAttributeString ? helmetAttributeString.split(',') : []
  const attributesToRemove = [].concat(helmetAttributes)
  const attributeKeys = Object.keys(attributes)
  for (let i = 0; i < attributeKeys.length; i++) {
    const attribute = attributeKeys[i]
    const value = attributes[attribute] || ''
    if (elementTag.getAttribute(attribute) !== value) {
      elementTag.setAttribute(attribute, value)
    }
    if (helmetAttributes.indexOf(attribute) === -1) {
      helmetAttributes.push(attribute)
    }
    const indexToSave = attributesToRemove.indexOf(attribute)
    if (indexToSave !== -1) {
      attributesToRemove.splice(indexToSave, 1)
    }
  }
  for (let i = attributesToRemove.length - 1; i >= 0; i--) {
    elementTag.removeAttribute(attributesToRemove[i])
  }
  if (helmetAttributes.length === attributesToRemove.length) {
    elementTag.removeAttribute(HELMET_ATTRIBUTE)
  } else if (elementTag.getAttribute(HELMET_ATTRIBUTE) !== attributeKeys.join(',')) {
    elementTag.setAttribute(HELMET_ATTRIBUTE, attributeKeys.join(','))
  }
}
const updateTags = (type, tags) => {
  const headElement = document.head || document.querySelector(TAG_NAMES.HEAD)
  const tagNodes = headElement.querySelectorAll(type + '[' + HELMET_ATTRIBUTE + ']')
  const oldTags = Array.prototype.slice.call(tagNodes)
  const newTags = []
  let indexToDelete
  if (tags != null && tags.length) {
    tags.forEach(tag => {
      const newElement = document.createElement(type)
      for (const attribute in tag) {
        if (tag.hasOwnProperty(attribute)) {
          if (attribute === TAG_PROPERTIES.INNER_HTML) {
            newElement.innerHTML = tag.innerHTML
          } else if (attribute === TAG_PROPERTIES.CSS_TEXT) {
            if (newElement.styleSheet) {
              newElement.styleSheet.cssText = tag.cssText
            } else {
              newElement.prepend(document.createTextNode(tag.cssText))
            }
          } else {
            const value = typeof tag[attribute] === 'undefined' ? '' : tag[attribute]
            newElement.setAttribute(attribute, value)
          }
        }
      }
      newElement.setAttribute(HELMET_ATTRIBUTE, 'true')

      // Remove a duplicate tag from domTagstoRemove, so it isn't cleared.
      if (oldTags.some((existingTag, index) => {
        indexToDelete = index
        return newElement.isEqualNode(existingTag)
      })) {
        oldTags.splice(indexToDelete, 1)
      } else {
        newTags.push(newElement)
      }
    })
  }
  oldTags.forEach(tag => tag.parentNode.removeChild(tag))
  const target = document.getElementById('append-helmet')
  newTags.forEach(tag => {
    target.after(tag)
  })
  return {
    oldTags,
    newTags
  }
}
const convertReactPropstoHtmlAttributes = function (props, initAttributes) {
  if (initAttributes === void 0) {
    initAttributes = {}
  }
  return Object.keys(props).reduce((obj, key) => {
    obj[HTML_TAG_MAP[key] || key] = props[key]
    return obj
  }, initAttributes)
}

const Helmet = Component => {
  let _class
  return _class = class HelmetWrapper extends React.Component {
    /**
     * @param {Object} base: {"target": "_blank", "href": "http://mysite.com/"}
     * @param {Object} bodyAttributes: {"className": "root"}
     * @param {String} defaultTitle: "Default Title"
     * @param {Boolean} defer: true
     * @param {Boolean} encodeSpecialCharacters: true
     * @param {Object} htmlAttributes: {"lang": "en", "amp": undefined}
     * @param {Array} link: [{"rel": "canonical", "href": "http://mysite.com/example"}]
     * @param {Array} meta: [{"name": "description", "content": "Test description"}]
     * @param {Array} noscript: [{"innerHTML": "<img src='http://mysite.com/js/test.js'"}]
     * @param {Function} onChangeClientState: "(newState) => console.log(newState)"
     * @param {Array} script: [{"type": "text/javascript", "src": "http://mysite.com/js/test.js"}]
     * @param {Array} style: [{"type": "text/css", "cssText": "div { display: block; color: blue; }"}]
     * @param {String} title: "Title"
     * @param {Object} titleAttributes: {"itemprop": "name"}
     * @param {String} titleTemplate: "MySite.com - %s"
     */

    // Component.peek comes from react-side-effect:
    // For testing, you may use a static peek() method available on the returned component.
    // It lets you get the current state without resetting the mounted instance stack.
    // Don’t use it for anything other than testing.

    static set canUseDOM (canUseDOM) {
      Component.canUseDOM = canUseDOM
    }

    shouldComponentUpdate (nextProps) {
      return !isEqual(this.props, nextProps)
    }

    mapNestedChildrenToProps (child, nestedChildren) {
      if (!nestedChildren) {
        return null
      }
      switch (child.type) {
        case TAG_NAMES.SCRIPT:
        case TAG_NAMES.NOSCRIPT:
          return {
            innerHTML: nestedChildren
          }
        case TAG_NAMES.STYLE:
          return {
            cssText: nestedChildren
          }
      }
      throw new Error('<' + child.type + ' /> elements are self-closing and can not contain children. Refer to our API for more information.')
    }

    flattenArrayTypeChildren (_ref) {
      const {
        child,
        arrayTypeChildren,
        newChildProps,
        nestedChildren
      } = _ref
      return {
        ...arrayTypeChildren,
        [child.type]: [...(arrayTypeChildren[child.type] || []), {
          ...newChildProps,
          ...this.mapNestedChildrenToProps(child, nestedChildren)
        }]
      }
    }

    mapObjectTypeChildren (_ref2) {
      const {
        child,
        newProps,
        newChildProps,
        nestedChildren
      } = _ref2
      switch (child.type) {
        case TAG_NAMES.TITLE:
          return {
            ...newProps,
            [child.type]: nestedChildren,
            titleAttributes: {
              ...newChildProps
            }
          }
        case TAG_NAMES.BODY:
          return {
            ...newProps,
            bodyAttributes: {
              ...newChildProps
            }
          }
        case TAG_NAMES.HTML:
          return {
            ...newProps,
            htmlAttributes: {
              ...newChildProps
            }
          }
      }
      return {
        ...newProps,
        [child.type]: {
          ...newChildProps
        }
      }
    }

    mapArrayTypeChildrenToProps (arrayTypeChildren, newProps) {
      let newFlattenedProps = {
        ...newProps
      }
      Object.keys(arrayTypeChildren).forEach(arrayChildName => {
        newFlattenedProps = {
          ...newFlattenedProps,
          [arrayChildName]: arrayTypeChildren[arrayChildName]
        }
      })
      return newFlattenedProps
    }

    warnOnInvalidChildren (child, nestedChildren) {
      if (process.env.NODE_ENV !== 'production') {
        if (!VALID_TAG_NAMES.some(name => child.type === name)) {
          if (typeof child.type === 'function') {
            return warn('You may be attempting to nest <Helmet> components within each other, which is not allowed. Refer to our API for more information.')
          }
          return warn('Only elements types ' + VALID_TAG_NAMES.join(', ') + ' are allowed. Helmet does not support rendering <' + child.type + '> elements. Refer to our API for more information.')
        }
        if (nestedChildren && typeof nestedChildren !== 'string' && (!Array.isArray(nestedChildren) || nestedChildren.some(nestedChild => typeof nestedChild !== 'string'))) {
          throw new Error('Helmet expects a string as a child of <' + child.type + '>. Did you forget to wrap your children in braces? ( <' + child.type + '>{``}</' + child.type + '> ) Refer to our API for more information.')
        }
      }
      return true
    }

    mapChildrenToProps (children, newProps) {
      let arrayTypeChildren = {}
      React.Children.forEach(children, child => {
        if (!child || !child.props) {
          return
        }
        const _child$props = child.props
        const {
          children: nestedChildren
        } = _child$props
        const childProps = _objectWithoutProperties(_child$props, ['children'])
        const newChildProps = convertReactPropstoHtmlAttributes(childProps)
        this.warnOnInvalidChildren(child, nestedChildren)
        switch (child.type) {
          case TAG_NAMES.LINK:
          case TAG_NAMES.META:
          case TAG_NAMES.NOSCRIPT:
          case TAG_NAMES.SCRIPT:
          case TAG_NAMES.STYLE:
            arrayTypeChildren = this.flattenArrayTypeChildren({
              child,
              arrayTypeChildren,
              newChildProps,
              nestedChildren
            })
            break
          default:
            newProps = this.mapObjectTypeChildren({
              child,
              newProps,
              newChildProps,
              nestedChildren
            })
            break
        }
      })
      newProps = this.mapArrayTypeChildrenToProps(arrayTypeChildren, newProps)
      return newProps
    }

    render () {
      const _this$props = this.props
      const {
        children
      } = _this$props
      const props = _objectWithoutProperties(_this$props, ['children'])
      let newProps = {
        ...props
      }
      if (children) {
        newProps = this.mapChildrenToProps(children, newProps)
      }
      return /* #__PURE__ */React.createElement(Component, newProps)
    }
  }, _class.propTypes = {
    base: PropTypes.object,
    bodyAttributes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    defaultTitle: PropTypes.string,
    defer: PropTypes.bool,
    encodeSpecialCharacters: PropTypes.bool,
    htmlAttributes: PropTypes.object,
    link: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.arrayOf(PropTypes.object),
    noscript: PropTypes.arrayOf(PropTypes.object),
    onChangeClientState: PropTypes.func,
    script: PropTypes.arrayOf(PropTypes.object),
    style: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    titleAttributes: PropTypes.object,
    titleTemplate: PropTypes.string
  }, _class.defaultProps = {
    defer: true,
    encodeSpecialCharacters: true
  }, _class.peek = Component.peek, _class
}
const NullComponent = () => null
const HelmetSideEffects = withSideEffect(reducePropsToState, handleClientStateChange
// mapStateOnServer
)(NullComponent)
const HelmetExport = Helmet(HelmetSideEffects)

exports.Helmet = HelmetExport
exports.default = HelmetExport
