// import colors from 'assets/theme/base/colors'
// const { info } = colors

export default {
  html: {
    scrollBehavior: 'smooth'
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important'
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: '#000000 !important',
    transition: 'color 150ms ease-in !important'
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: '#000000 !important'
  }
}
