export default {
  defaultProps: {
    color: 'transparent'
  },

  styleOverrides: {
    root: {
      boxShadow: 'none'
    }
  }
}
