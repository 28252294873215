import Insta from './Insta'
import Container from '@mui/material/Container'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import InstaLogo from 'assets/insta.png'
import TikTokLogo from 'assets/tiktok.png'
import FB from 'assets/fb.png'
import YT from 'assets/yt.png'
import Divider from '@mui/material/Divider'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function Gallery () {
  return (
    <Container sx={{ pt: 12, textAlign: 'center' }}>
      <HelmetMeta title='EMIRATES SKATING CLUB | Locations' />
      <Chip
        sx={{ mt: 2, mb: 4 }}
        avatar={<Avatar alt='insta' src={InstaLogo} />}
        label='From Our Insta Account'
        variant='outlined'
      />
      <Insta />
      <Divider />
      <Chip
        sx={{ my: 4, mx: 2 }}
        avatar={<Avatar alt='insta' src={TikTokLogo} />}
        label='Click For Our TikTok Account'
        variant='outlined'
        onClick={() => window.open('https://www.tiktok.com/@emiratesskating/', '_blank')}
      />
      <Chip
        sx={{ my: 4, mx: 2 }}
        avatar={<Avatar alt='insta' src={FB} />}
        label='Click For Our Facebook Account'
        variant='outlined'
        onClick={() => window.open('https://www.facebook.com/emiratesskateclub/photos_by', '_blank')}
      />
      <Chip
        sx={{ my: 4, mx: 2 }}
        avatar={<Avatar alt='insta' src={YT} />}
        label='Click For Our Youtube Account'
        variant='outlined'
        onClick={() => window.open('https://www.youtube.com/@Emiratesskating', '_blank')}
      />
    </Container>
  )
}
