import Team from './pages/Team/Team'
import About from './pages/About/About'
import Locations from './pages/Locations/Locations'
import Gallery from './pages/Gallery/Gallery'
import Contact from './pages/Contact/Contact'
import ClassesAbuDhabi from './pages/Classes/ClassesAbuDhabi'
import ClassesDubai from './pages/Classes/ClassesDubai'
// import SpecialDubai from './pages/SummerSpecial/SpecialDubai'
// import SpecialAbuDhabi from './pages/SummerSpecial/SpecialAbuDhabi'
import Competitions from './pages/Competitions/Competitions'

const routes = [
  /*
  {
    name: 'summer special',
    collapse: [
      {
        name: 'Abu Dhabi',
        route: '/summer-special/abu-dhabi',
        component: <SpecialAbuDhabi />
      },
      {
        name: 'Dubai',
        route: '/summer-special/dubai',
        component: <SpecialDubai />
      }
    ]
  },
   */
  {
    name: 'classes',
    collapse: [
      {
        name: 'Abu Dhabi',
        route: '/classes/abu-dhabi',
        component: <ClassesAbuDhabi />
      },
      {
        name: 'Dubai',
        route: '/classes/dubai',
        component: <ClassesDubai />
      }
    ]
  },
  {
    name: 'team',
    route: '/team',
    component: <Team />
  },
  {
    name: 'about',
    // icon: <Icon>article</Icon>,
    collapse: [
      {
        name: 'About The Club',
        route: '/about',
        component: <About />
      },
      {
        name: 'Locations',
        route: '/locations',
        component: <Locations />
      },
      {
        name: 'Gallery',
        route: '/gallery',
        component: <Gallery />
      },
      {
        name: 'Contact',
        route: '/contact',
        component: <Contact />
      }
    ]
  },
  {
    name: 'Competitions',
    route: '/competitions',
    component: <Competitions />
  }
]

export default routes
